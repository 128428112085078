import { Injectable } from '@angular/core';
import { Config } from '../config/config';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private config : Config) { }

  private token !: string;

  public saveToken(token: string): void {
    localStorage.setItem(this.config.auth_key, token);
    this.token = token;
  }

  public getToken(): string {
      if (!this.token) {
          this.token = localStorage.getItem(this.config.auth_key) || '';
      }
      return this.token;
  }

  public removeToken(): void {
    localStorage.removeItem(this.config.auth_key);
  }
}
