import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import 'moment-timezone';
import { serviceDetails } from '../schedule-meeting/schedule-meeting.model';
import { customerDetails, jobDetails } from './meeting-scheduled.model';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

@NgModule({
schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class CustomModule { }
@Component({
  selector: 'app-meeting-scheduled',
  templateUrl: './meeting-scheduled.component.html',
  styleUrls: ['./meeting-scheduled.component.scss']
})
export class MeetingScheduledComponent implements OnInit {
  serviceScheduleDetails !: serviceDetails;
  jobDetails !: jobDetails;
  customerDetails: customerDetails;
  tz = moment.tz.guess();
  constructor() {
    this.serviceScheduleDetails =JSON.parse(localStorage.getItem('serviceDetails') || '{}');
    let customerDetails =JSON.parse(localStorage.getItem('customerDetails') || '{}');
    this.customerDetails = customerDetails.job.customer.customer_address;
    this.jobDetails={
      job_title : customerDetails.job.job_title
    }
  }
  formatTime(dateTime: any){
    return moment.utc(dateTime).tz(this.tz).format('hh:mm A');
  }

  ngOnInit(): void {
  }

}
