import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import {CustomerDetailsService} from './customer-details.service'

@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.scss']
})
export class CustomerDetailsComponent implements OnInit {
  showFormError : string = '';
  customerDetails !: FormGroup;
  serviceDetails !: any;
  start_time !: string;
  end_time !:string;
  invalid : any = '';
  newJob !: Object;
  job_category !: any;
  tz = moment.tz.guess();
  constructor(private customerDetailsService : CustomerDetailsService, private spinner : NgxSpinnerService, private router : Router) { 
    this.serviceDetails =JSON.parse(localStorage.getItem('serviceDetails') || '{}');
    this.start_time = moment(this.serviceDetails.slot.start_time).format('hh:mm A');
    this.end_time = moment(this.serviceDetails.slot.end_time).format('hh:mm A');
    // this.zipcode.setValue(this.serviceDetails.zipcode);
    this.customerDetails = new FormGroup({
      'name' : new FormControl('', Validators.required),
      'email' : new FormControl('', [Validators.required, Validators.email]),
      'number' : new FormControl(''),
      'address' : new FormControl('', Validators.required),
      'city' : new FormControl('', Validators.required),
      'state' : new FormControl('', Validators.required),
      'country' : new FormControl('', Validators.required),
      'zipcode' : new FormControl({value : this.serviceDetails.zipcode, disabled:this.serviceDetails.zipcode}, Validators.required),
      'additional_notes' : new FormControl()
    })
  }
  getJobCategory(){
    this.customerDetailsService.getJobCategory()
      .then((res:any)=>{
        this.job_category = res.job_category_uid;
      }).catch(err=>{
        console.log("Error Loading Job Category",err)
      })
  }
  redirectToScheduler(){
    this.router.navigate(['']);
  }
  cancelScheduling(){
    localStorage.clear();
    this.router.navigate(['']);
  }
  formatTime(dateTime: any){
    return moment.utc(dateTime).tz(this.tz).format('hh:mm A');
  }
  getCustomerDetails(){
    if(this.customerDetails.controls['name'].invalid){
      this.showFormError = 'name';
      document.getElementById('name')?.focus();
    }
    else if(this.customerDetails.controls['email'].invalid){
      this.showFormError = 'email';
      document.getElementById('email')?.focus();
    }
    else if(this.customerDetails.controls['address'].invalid){
      this.showFormError = 'address';
      document.getElementById('address')?.focus();
    }
    else if(this.customerDetails.controls['city'].invalid){
      this.showFormError = 'city';
      document.getElementById('city')?.focus();
    }
    else if(this.customerDetails.controls['country'].invalid){
      this.showFormError = 'country';
      document.getElementById('country')?.focus();
    }
    else if(this.customerDetails.controls['state'].invalid){
      this.showFormError = 'state';
      document.getElementById('state')?.focus();
    }
    else if(this.customerDetails.controls['zipcode'].invalid){
      this.showFormError = 'zipcode';
      document.getElementById('zipcode')?.focus();
    }
    else{    
      this.spinner.show();
      let scheduled_start_time = moment(this.serviceDetails.slot.start_time, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm:ss');
      let scheduled_end_time = moment(this.serviceDetails.slot.end_time, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm:ss');
      this.newJob = {
        "job":{
          "job_priority": "MEDIUM",
          "job_type": "NEW",
          'job_title' : 'Service Request',
          'job_category' : this.job_category,
          'job_description' :this.customerDetails.value['additional_notes']?this.customerDetails.value['additional_notes']:'',
          "customer": {
              "customer_email": this.customerDetails.value['email'],
              "customer_first_name": this.customerDetails.value['name'],
              "customer_contact_no": {
                  "mobile": this.customerDetails.value['number']
              },
              "customer_billing_address": {
                  "street": this.customerDetails.value['address'],
                  "city": this.customerDetails.value['city'],
                  "state": this.customerDetails.value['state'],
                  "zip_code": this.customerDetails.value['zipcode']
              },
              "customer_address": {
                "street": this.customerDetails.value['address'],
                "city": this.customerDetails.value['city'],
                "state": this.customerDetails.value['state'],
                "zip_code": this.customerDetails.value['zipcode'],
                "first_name": this.customerDetails.value['name'],
                "last_name": "",
                "email": this.customerDetails.value['email'],
                "geo_cordinates": [13.0418, 80.2341]
              }
          },
          "scheduled_start_time": scheduled_start_time,
          "scheduled_end_time": scheduled_end_time,
          "assigned_to": [{
              "team_uid": this.serviceDetails.team_uid,
              "user_uid": this.serviceDetails.slot.user_uid
          }],
          "team_uid": this.serviceDetails.team_uid,
        }
      }
      localStorage.setItem('customerDetails',JSON.stringify(this.newJob || '{}'))
      this.customerDetailsService.createJob(this.newJob)
      .then(res => {
        this.spinner.hide();
        if(res.type == 'success'){
          this.router.navigate(['/meeting_scheduled'])
        }
      }).catch(err => {
        this.spinner.hide();
        console.log("Error Creating Job",err)
      })
    }
  } 
  ngOnInit(): void {
    this.getJobCategory();
  }

}
