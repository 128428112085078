import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CustomerDetailsComponent } from './customer-details/customer-details.component';
import { MeetingScheduledComponent } from './meeting-scheduled/meeting-scheduled.component';
import { ScheduleMeetingComponent } from './schedule-meeting/schedule-meeting.component';

const routes: Routes = [
  {
    path:"",
    component:ScheduleMeetingComponent
  },
  {
    path:"get_details",
    component:CustomerDetailsComponent
  },
  {
    path:"meeting_scheduled",
    component:MeetingScheduledComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
