
export enum HttpMethod {
    Get = "GET",
    Post = "POST",
    Put = "PUT",
    Patch = "PATCH",
    Delete = "DELETE"
}

export enum ApiEndPoints {
    assisted_scheduling = '/assisted_scheduling',
    team = '/team_uid',
    job_category= '/job_category_uid',
    jobs = '/jobs'
}