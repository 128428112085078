import { Injectable } from '@angular/core';
import { Config } from '../config/config';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiEndPoints, HttpMethod } from '../shared/common.model';
import { from, Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerDetailsService {
  constructor(private http : HttpClient, private config:Config, private authService : AuthService) { }
  public getJobCategory(){
  return this.http.request(
    HttpMethod.Get, 
    this.config.BASE + ApiEndPoints.job_category,
    {
      headers: {
        'Authorization' : "Basic " + window.btoa('user:password')
      }
    }).toPromise();
}
  public createJob(data:any): Promise<any> {
    const fullURL = this.config.BASE + ApiEndPoints.assisted_scheduling;
    return this.http.request(
        HttpMethod.Post, 
        this.config.BASE + ApiEndPoints.jobs, 
        {
          body: data,
          headers: {
            'Authorization' : "Basic " + window.btoa('user:password')
          },
          // pasrams: params,
        } ).toPromise();
  }
}
