<div class="d-flex justify-content-center align-items-center outer_div bg_grey">
    <mat-card class="demo-inline-card">
        <div class="confirm_icon d-flex justify-content-center">
            <!-- <script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script>
            <lottie-player src="https://assets4.lottiefiles.com/packages/lf20_agw0Fx.json"  background="transparent"  speed="1" autoplay></lottie-player> -->
            <img class="lottie-player" src="../../assets/images/misc/animation_300_kclj2j79.gif" alt="">
        </div>
      <mat-card-title class="mb-3 mt-3">Service is Scheduled</mat-card-title>
      <mat-card-content>
        <ul class="list-group list-group-flush">
            <li class="list-group-item d-flex justify-content-between">
                <span class="col-lg-2 font-weight-bold">What</span>
                <p class="card_list_item col-lg-9">{{jobDetails.job_title}} is scheduled for {{customerDetails.first_name}}</p>
            </li>
            <li class="list-group-item d-flex justify-content-between">
                <span class="col-lg-2 font-weight-bold">When</span>
                <p class="card_list_item col-lg-9">{{serviceScheduleDetails.day}} {{serviceScheduleDetails.date}}, {{formatTime(this.serviceScheduleDetails.slot.start_time)}} - {{formatTime(this.serviceScheduleDetails.slot.end_time)}} </p>
            </li>
            <li class="list-group-item d-flex justify-content-between">
                <span class="col-lg-2 font-weight-bold">Where</span>
            <p class="card_list_item text-break col-lg-9">{{customerDetails.street}}, {{customerDetails.city}}, {{customerDetails.state}} {{serviceScheduleDetails.zipcode}}</p>
            </li>
        </ul>
    </mat-card-content>
    </mat-card>
</div>