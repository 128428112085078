import { Injectable } from '@angular/core';
import { Config } from '../config/config';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiEndPoints, HttpMethod } from '../shared/common.model';
import { from, Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
@Injectable({
  providedIn: 'root'
})
export class ScheduleMeetingService {

  constructor(private http : HttpClient, private config:Config, private authService : AuthService) { }
  public getTeams(){
    return this.http.request(
      HttpMethod.Get, 
      this.config.BASE + ApiEndPoints.team,
      {
        headers: {
          'Authorization' : "Basic " + window.btoa('user:password')
        }
      }).toPromise();
  }
  public getSlots(from_date: string, to_date: string,job_duration: string, zipcode : string, team_uid : string): Promise<any> {
    const params = new HttpParams()
     .set('from_date', from_date)
     .set('to_date',to_date)
     .set('job_duration',job_duration)
     .set('zipcode',zipcode)
     .set('team_uid',team_uid);
    const fullURL = `${this.config.BASE + ApiEndPoints.assisted_scheduling}?${params.toString()}`;
    return this.http.request(
        HttpMethod.Get, 
        this.config.BASE + ApiEndPoints.assisted_scheduling, 
        {
          headers: {
            'Authorization' : "Basic " + window.btoa('user:password')
          },
          params: params,
        } ).toPromise();
  }
}
