<div class="d-flex justify-content-center align-items-center outer_div bg_grey">
    <mat-card class="demo-inline-card">
      <img class="mat-card-xs-image mb-4" src="assets/images/services_img.png">
        <!-- <mat-card-subtitle>Peer Richelsen</mat-card-subtitle> -->
        <mat-card-title>Schedule your Service</mat-card-title>
        <mat-card-content>
        <mat-list>
          <mat-list-item>
            <div class="d-flex">
              <mat-icon matListIcon [inline]="true" class="text-muted">watch_later</mat-icon>
              <p matLine class="card_list_item text-muted">{{serviceDetails.job_duration}} minutes ({{formatTime(serviceDetails.slot.start_time)}} - {{formatTime(serviceDetails.slot.end_time)}})</p>
            </div>
          </mat-list-item>
          <mat-list-item>
            <div class="d-flex">
              <mat-icon matListIcon [inline]="true" class="text-muted">room</mat-icon>
              <p matLine class="card_list_item text-muted">{{serviceDetails.zipcode}}</p>
            </div>
          </mat-list-item>
          <mat-list-item>
            <div class="d-flex">
              <mat-icon matListIcon [inline]="true" class="text-success">event</mat-icon>
              <p matLine class="card_list_item text-success text-break">{{serviceDetails.day}} {{serviceDetails.date}}</p>
            </div>
          </mat-list-item>
        </mat-list>
        <p>A quick screen share demo or longer conversation</p>
        <button (click)="redirectToScheduler()" class="btn btn-dark pull-right" id="go_back">Go Back</button>
      </mat-card-content>
    </mat-card>
    <mat-card class="demo-inline-card-form">
      <form [formGroup]="customerDetails" (submit)="getCustomerDetails()">
        <!-- <div class="form-group">
          <label class="form-label fw-bold" for="name">Job Title <span class="mandatory">*</span></label>
          <input type="text" class="form-control mb-1" formControlName="job_title" id="job_title" placeholder="Give a title for Job"> -->
          <!-- <span class="text-danger" *ngIf="!customerDetails.controls['job_title'].valid">*Job Title is mandatory</span> -->
        <!-- </div> -->
        
        <div class="form-group">
          <label class="form-label fw-bold" for="name">Name <span class="mandatory">*</span></label>
          <input type="text" class="form-control mb-1" formControlName="name" id="name" placeholder="John Doe" required>
          <span class="text-danger" *ngIf="this.customerDetails.controls['name'].invalid && showFormError == 'name'">Customer Name is mandatory</span>
        </div>
        <div class="row">
          <div class="col-md-6 col-lg-6">
            <div class="form-group">
              <label class="form-label fw-bold" for="customer_email">Email address <span class="mandatory">*</span></label>
              <input class="form-control mb-1" autocomplete="off" formControlName="email" id="customer_email" placeholder="you@example.com" required>
              <span class="text-danger" *ngIf="this.customerDetails.controls['email'].invalid && showFormError == 'email'">Customer Email is mandatory</span>
            </div>
          </div>
          <div class="col-md-6 col-lg-6">
            <div class="form-group">
              <label class="form-label fw-bold" for="customer_email">Phone Number</label>
              <input class="form-control mb-1" autocomplete="off" formControlName="number" id="number" placeholder="Enter Phone Number">
            </div>
          </div>
        </div>
        <div class="form-group">
          <label>Service Address <span class="mandatory">*</span></label>
          <input type="text" class="form-control mb-1" formControlName="address" places-auto-complete="" placeholder="Enter Address" autocomplete="off" required>
          <span class="text-danger" *ngIf="this.customerDetails.controls['address'].invalid && showFormError == 'address'">Address is mandatory</span>
        </div>
        <div class="row">
          <div class="col-md-6 col-lg-6">
            <div class="form-group">
              <label>City <span class="mandatory">*</span></label>
              <input type="text" class="form-control mb-1" formControlName="city" placeholder="Enter City" required>
              <span class="text-danger" *ngIf="this.customerDetails.controls['city'].invalid && showFormError == 'city'">City is mandatory</span>
            </div>
          </div>
          <div class="col-md-6 col-lg-6">
            <div class="form-group">
              <label>State <span class="mandatory">*</span></label>
              <input type="text" class="form-control mb-1" formControlName="state" placeholder="Enter State" required>
              <span class="text-danger" *ngIf="this.customerDetails.controls['state'].invalid && showFormError == 'state'">State is mandatory</span>
            </div>
          </div>
          <div class="col-md-6 col-lg-6">
            <div class="form-group">
              <label>Country <span class="mandatory">*</span></label>
              <input type="text" class="form-control mb-1" formControlName="country" placeholder="Enter Country" required>
              <span class="text-danger" *ngIf="this.customerDetails.controls['country'].invalid && showFormError == 'country'">Country is mandatory</span>
            </div>
          </div>
          <div class="col-md-6 col-lg-6">
            <div class="form-group">
                <label>Zipcode <span class="mandatory">*</span></label>
                <input type="text" class="form-control mb-1" formControlName="zipcode" placeholder="Enter Zipcode" required>
                <span class="text-danger" *ngIf="this.customerDetails.controls['zipcode'].invalid && showFormError== 'zipcode'">Zipcode is mandatory</span>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label class="form-label fw-bold" for="job_description">Any Other Notes</label>
          <textarea class="form-control mb-1" name="job_description" id="job_description" rows="3" formControlName="additional_notes"></textarea>
        </div>
        <div class="form-footer">
          <button class="btn btn-dark pull-right">Confirm</button>
          <button (click)="cancelScheduling()" class="btn btn-default pull-right ml-2">Cancel</button>
        </div>
      </form>
    </mat-card>
    <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">  
      <!-- <p style="color: white">Please Wait. </p>   -->
    </ngx-spinner>
</div>